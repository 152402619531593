modulejs.define 'slzr/components/instance_list_item',
  ['jquery', 'react', 'underscore', 'slzr/components/date_input_row', 'slzr/components/date_input_floating_row', 'slzr/react/form_theme_context'],
  ($, React, _, DateInputRow, DateInputFloatingRow, FormThemeContext) ->

    class InstanceListItem extends React.Component
      @contextType: FormThemeContext

      @defaultProps =
        instances: null
        hidden: false

      constructor: (props) ->
        super props

        # HTML ID prefix to allow accessibility associations
        @id_prefix = _.uniqueId('instance-item-')

        # Initial state
        @state =
          editing: false

      render: ->
        {start_date, start_time, end_time} = @props.instance

        data = {
          # Short forms (for form data)
          start_date: start_date?.toString('yyyy-MM-dd')
          start_time: start_time?.toString('HH:mm:00')
          end_time:   end_time?.toString('HH:mm:00')

          # Long forms (for display)
          start_date_long: start_date?.toString(Slzr.Formats.LongDate)
          start_time_long: start_time?.toString(Slzr.Formats.LongTime)
          end_time_long:   end_time?.toString(Slzr.Formats.LongTime)

          # Objects (mainly for event callbacks, if needed)
          start_date_obj: start_date
          start_time_obj: start_time
          end_time_obj:   end_time
        }

        date_string = data.start_date_long
        time_string = ''
        serialized_value = [data.start_date, data.start_time, data.end_time].join(',')

        if data.start_time
          date_string += ' ' + data.start_time_long
          date_string += (" - " + data.end_time_long) if data.end_time

        # Inner contents of the row, either the text or the edit state
        row_contents = if @state.editing
          `<div className="expanded" role="group" aria-labelledby={this.id_prefix + "-label"}>
            <span id={this.id_prefix + "-label"} className="instance_time">{date_string}</span>
            <div className="em-form_column-2">
              <DateInputFloatingRow label="Start Time" name="instance_start_time" type="time"
                                    timeError="Invalid start time"
                                    initialValue={start_time}
                                    ref="startTime"
                                    onEnterKey={this._saveInstance}/>
              <DateInputFloatingRow label="End Time" name="instance_end_time" type="time"
                                    timeError="Invalid end time"
                                    initialValue={end_time}
                                    ref="endTime"
                                    onEnterKey={this._saveInstance}/>
            </div>


            <div className="action_button inline_form_action_buttons">
              <a href="#" className="em-link" onClick={this._editInstance.bind(this, false)}>Cancel</a>
              <input type="submit" className="em-button em-primary" value="Update" onClick={this._saveInstance}/>
            </div>
          </div>`
        else
          `<p>
            <span id={this.id_prefix + "-label"} className="instance_time">{date_string}</span>
            <span className="deletex">
                <a href="#"
                   onClick={this._editInstance.bind(this, true)}
                   title="Edit Time"
                   aria-describedby={this.id_prefix + "-label"}
                   className="edit_button">
                  <i className="fas fa-edit">
                    <span className="sr-only">Edit Time</span>
                  </i>
                </a>
                <a href="#"
                   onClick={this._removeInstance}
                   className="deletex_button"
                   title="Remove Instance"
                   aria-describedby={this.id_prefix + "-label"}>
                  <i className="fas fa-trash-alt">
                    <span className="sr-only">Remove Instance</span>
                  </i>
                </a>
            </span>
          </p>`

        if @props.isRenderedInAdmin
          # Inner contents of the row, either the text or the edit state
          row_contents = if @state.editing
            `<h3 className="expanded" role="group" aria-labelledby={this.id_prefix + "-label"}>
              <span id={this.id_prefix + "-label"} className="instance_time">{date_string}</span>
              <DateInputRow label="Start Time" name="instance_start_time" type="time"
                            timeError="Invalid start time"
                            initialValue={start_time}
                            ref="startTime"
                            onEnterKey={this._saveInstance}
                            {...this.context.textInputRow} />
              <DateInputRow label="End Time" name="instance_end_time" type="time"
                            timeError="Invalid end time"
                            initialValue={end_time}
                            ref="endTime"
                            onEnterKey={this._saveInstance}
                            {...this.context.textInputRow} />

              <div className="action_button inline_form_action_buttons">
                <a href="#" className="em-link" onClick={this._editInstance.bind(this, false)}>Cancel</a>
                <input type="submit" className="btn btn-color" value="Update"
                       onClick={this._saveInstance}/>
              </div>
            </h3>`
          else
            `<h3 role="group" aria-labelledby={this.id_prefix + "-label"}>
              <span id={this.id_prefix + "-label"} className="instance_time">
                {date_string}
              </span>
              <span className="deletex">
                <a href="#"
                   onClick={this._editInstance.bind(this, true)}
                   title="Edit Time"
                   aria-describedby={this.id_prefix + "-label"}
                   className="edit_button">
                  <i className="localist-icon-edit-small">
                    <span className="sr-only">Edit Time</span>
                  </i>
                </a>
                <a href="#"
                   onClick={this._removeInstance}
                   title="Remove Instance"
                   aria-describedby={this.id_prefix + "-label"}
                   className="deletex_button">
                  <i className="localist-icon-trash-small">
                    <span className="sr-only">Remove Instance</span>
                  </i>
                </a>
              </span>
            </h3>`

        hidden_field = `<input type="hidden" name="scheduled_date[]" value={serialized_value} />`

        # Result: div for the row, or only the input element if it's hidden
        if @props.hidden
          hidden_field
        else
          `<div className="item event_item" data-date={data.start_date} data-instance="true" role="group" aria-labelledby={this.id_prefix + "-label"}>
            {row_contents}
            {hidden_field}
          </div>`

      _removeInstance: (evt) =>
        evt.preventDefault()

        if @props.instance.lock_reason == 'has_tickets'
          alert 'There are tickets sold for this event instance. Please assign all associated tickets to a new event instance before deleting.'
          return

        @props.eventInstances.removeAtIndex @props.index

      _editInstance: (editing, evt) =>
        @setState editing: editing
        evt.preventDefault()

      _saveInstance: (evt) =>
        start_time = @refs.startTime.state.currentValue
        end_time = @refs.endTime.state.currentValue

        # Call the handler with the updated data
        @props.onInstanceChanged?(
          start_date: @props.instance.start_date
          start_time: start_time
          end_time: end_time
        )

        @setState editing: false

        evt.preventDefault()