modulejs.define 'slzr/react/register_modal',
  ['jquery', 'react', 'react-dom', 'prop-types', 'slzr/modal-focus-trap'],
  ($, React, ReactDOM, PropTypes, FocusTrap) ->

    class RegisterModal extends React.Component
      @propTypes:
        eventName: PropTypes.string
        eventLocation: PropTypes.string
        eventPhotoUrl: PropTypes.string
        # Callback to abort checkout
        onCancel: PropTypes.func
        hideHeader: PropTypes.bool
        # Modal container element
        modalContainerElement: PropTypes.element
        isModalContainer: PropTypes.bool

      constructor: (props) ->
        super props
        this._el = null

      componentDidMount: =>
        $('html, body').addClass('em-scroll-body')
        setTimeout (-> $('.em-register_modal').addClass('em-open')), 0
        if this.props.isModalContainer
          FocusTrap.trap(this._el, priority: 1)

      componentWillUnmount: ->
        $('html, body').removeClass('em-scroll-body')
        if this.props.isModalContainer
          FocusTrap.untrap()

      # Handle Escape key presses to close the modal
      onKeyDown: (event) =>
        return if event.defaultPrevented

        switch event.key
          when 'Escape'
            event.preventDefault()
            this.closeModal(event)



      setRef: (ref) => this._el = ref

      closeModal: (event) =>
        this.props.onHideModal?(event)

      render: ->
        ReactDOM.createPortal(@renderContent(), this.props.modalContainerElement)

      renderContent: ->
        `<div className="em-register_modal em-register-checkout"
              role="dialog"
              aria-modal="true"
              onKeyDown={this.onKeyDown}
              ref={this.setRef}>
          <div className="em-modal_container">
            <div className="em-modal_header">
              <label role="heading" aria-level="1">{this.props.headerLabel}</label>
              <a href="#" className="cancel_link em-link em-arrow-left" onClick={this.closeModal}>
                <i className="fas fa-times"></i>
                <span className="em-close_text">Close</span>
              </a>
            </div>
            <div className="em-modal_header-img">
              <img alt={this.props.eventPhotoCaption} className="img_big " height="150" src={this.props.eventPhotoUrl} width="200" />
            </div>
            {
                !this.props.hideHeader && <h2>{this.props.eventName}</h2>
            }
            {
                !this.props.hideHeader && <span className="em-register_event_address">{this.props.eventLocation}</span>
            }
            {this.props.children}
          </div>

          <div className="em-modal_overlay" onClick={this.props.closeModal}></div>
        </div>`