# Widget Builder javascript functions

namespace 'Slzr.Page', (exports, top) ->
  $ = jQuery = Slzr.jQuery

  class exports.WidgetBuilder
    constructor: ->
      @form = $('[data-form="widget-builder"]')
      @form.find('[data-action="build-widget-code"]').on 'click', @buildCode
      @form.find('[data-action="preview-widget"]').on 'click', @openPreview

      # Update initial state
      @updateVisibilityForType()
      @updateVisibilityForTemplate()

      $('[data-hide-if]').each ->
        $this = $(this)
        target = $this.data('hide-if')

        obj = $this.closest('form').find('[name="' + target + '"]')
        obj.change -> $this.toggle $(this).checked

      $('#widget_type').change @updateVisibilityForType
      $('#template').change @updateVisibilityForTemplate

    updateVisibilityForTemplate: =>
      the_template = $('#template').val()
      # Default to no template set if it's undefined or null (i.e. selector isn't on the form)
      the_template = '' unless the_template?

      @form.find("[data-default-template]").toggle(the_template == '')
      @form.find("[data-default-only]").prop('disabled', the_template != '')

      @form.find("[data-not-default-template]").toggle(the_template != '')
      @form.find("[data-not-default]").prop('disabled', the_template == '')

    updateVisibilityForType: =>
      @form.find('[data-widget-types]').hide()
      @form.find("[data-widget-types~='#{$('#widget_type').val()}']").show()

    buildCode: =>
      the_id = Math.floor(Math.random() * 100000000)
      the_url = @buildURL(container: "localist-widget-#{the_id}")

      $('#x-widget-javascript').show()
      $('#x-widget-html, #x-widget-rss').hide()

      js_code = '<div id="localist-widget-' + the_id + '" class="localist-widget"></div><' + 'script defer type="text/javascript"\nsrc="' + the_url + '"><' + '/script>'

      # Append logo
      if !Slzr.Meta['unbranded-widget']
        js_code += '<div id="lclst_widget_footer"><a style="margin-left:auto;margin-right:auto;display:block;width:81px;margin-top:10px;" title="Widget powered by Concept3D Event Calendar Software" href="https://www.localist.com?utm_source=widget&utm_campaign=widget_footer&utm_medium=branded%20link"><img src="//d3e1o4bcbhmj8g.cloudfront.net/assets/platforms/default/about/widget_footer.png" alt="Localist Online Calendar Software" style="vertical-align: middle;" width="81" height="23"></a></div>'

      $('#x-widget-javascript-code').val(js_code).focus().select()

      $.post("/static/track_widget_create");

    openPreview: =>
      the_url = @buildURL(format: 'html')
      window.open the_url, 'widgetPreview'

    buildURL: (overrides={}) =>
      params = {}
      opts = {}
      arrayValues = ['types', 'exclude_types', 'venues', 'exclude_venues', 'groups', 'exclude_groups', 'departments', 'exclude_departments']
      
      # Collect attributes
      my_form = @form
      _(my_form.serializeArray()).each (el) ->
        # Handle special array values
        if _.contains(arrayValues, el.name)
          opts[el.name] ||= []
          opts[el.name].push el.value
        else
          # Don't include placeholders
          in_el = $('[name="' + el.name + '"]', my_form)
          if in_el.attr('placeholder') == el.value
            opts[el.name] = ''
          else
            opts[el.name] = el.value

      opts = $.extend opts, overrides

      # Clean up other inputs and add to params
      switch opts.event_source
        when 'schools', '', undefined
          if opts.campuses? && !opts.campuses.isBlank()
            params['campuses'] = opts.campuses
          else if opts.schools? && !opts.schools.isBlank()
            params['schools'] = opts.schools

        when 'cities'
          if opts.neighborhoods && !opts.neighborhoods.isBlank()
            params['neighborhoods'] = opts.neighborhoods
          else if opts.cities && !opts.cities.isBlank()
            params['cities'] = cities
      
      # Format array values
      arrayValues.forEach (key) =>
        if opts[key] && opts[key] instanceof Array
          params[key] = (_.reject opts[key], (el) ->
            el == 0 || el.isBlank()
          ).join(',')
          delete params[key] if params[key].isBlank()
        else if opts[key]? && !opts[key].isBlank()
          params[key] = opts[key].split(/\s*,\s*/).join(',')

      if !opts.style? && opts.include_styles?
        opts.style = if opts.include_styles == '1' then '' else 'none'
        delete opts.include_styles

      # Other values
      params['days'] = if opts.days? && !opts.days.isBlank() then opts.days else 31
      params['num'] = if opts.num? && !opts.num.isBlank() then opts.num else 50
      params['age_limit'] = opts.age_requirement if opts.age_requirement? && opts.age_requirement != '0'
      params['picks'] = 1 if opts.staff_picks == '1'
      params['skip_recurring'] = 1 if opts.skip_recurring == '1'
      params['sponsored'] = 1 if opts.sponsored == '1'
      params['tags'] = opts.tags.split(/\s*,\s*/).join(',') unless opts.tags?.isBlank()
      params['all_instances'] = 1 if opts.all_instances == '1'
      params['match'] = opts.match if opts.match? && !opts.match.isBlank()
      params['hidedesc'] = 1 if opts.hidedesc == '1'
      params['hideimage'] = 1 if opts.hideimage == '1'
      params['show_times'] = 0 if opts.show_times == '0'
      params['show_types'] = 0 if opts.show_types == '0'
      params['show_view_all_cta'] = 0 if opts.show_view_all_cta == '0'
      params['target_blank'] = 1 if opts.target_blank == '1'
      params['html_descriptions'] = 1 if opts.html_descriptions == '1'
      params['expand_descriptions'] = 1 if opts.expand_descriptions == '1'
      params['hide_past'] = 1 if opts.hide_past == '1'
      params['experience'] = opts.experience if opts.experience
      params['exclude_experience'] = opts.exclude_experience if opts.exclude_experience

      # Format
      params['container'] = opts.container if opts.container && !opts.container.isBlank()
      params['format'] = 'html' if opts.format == 'html'
      params['format'] = 'rss' if opts.widget_type == 'rss'

      # Template and style

      if opts.style == 'modern' && (!opts.template || opts.template == '')
        params['template'] = 'modern'
      else if opts.style == 'card' && (!opts.template || opts.template == '')
        params['template'] = 'card'
      else
        params['style'] = opts.style if opts.style? && !opts.style.isBlank()
        params['template'] = opts.template if opts.template? && !opts.template.isBlank()

      # Build URL
      url = if opts.widget_type == 'combo' then Slzr.Meta['base-combo-widget-url'] else Slzr.Meta['base-widget-url']
      url += '?' + $.param(params)

  # Activate widget builder
  $ -> (new exports.WidgetBuilder if Slzr.Meta.page == 'widget-builder')