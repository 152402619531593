modulejs.define 'registration/components/checkout/compliance_form',
  ['react', 'prop-types', 'registration/utilities',
   'slzr/react/form_row'],
  (React, PropTypes, Utilities,
   FormRow) ->

    class ComplianceForm extends React.Component
      @propTypes:
        # Compliance level
        level: PropTypes.oneOf ["none", "light", "strong", "hardcore"]
        # Additional compliance text
        text: PropTypes.string
        # Agreement URL
        termsUrl: PropTypes.string
        # Platform Name
        platformName: PropTypes.string
        # Callback for agreement state change
        onAgreementChange: PropTypes.func
        # Agreement state
        agreementState: PropTypes.bool
        # Errors
        errors: PropTypes.string

      @defaultProps:
        agreementState: false
        errors: null

      # Render the implicit acceptance message
      renderImplicit: =>
        `<div>
          <p>By completing this form, I agree that I'd like to receive information from {this.props.platformName} related to events by email, or other forms of communication.</p>

          <p>You may unsubscribe at any time by following the instructions in the communications received. Your information will be handled in accordance with the Privacy Policy.</p>
        </div>`

      # Render the explicit acceptance message, optionally with the text
      renderExplicit: =>
        `<div>
          
            <p>By checking "I acknowledge" below, I agree that I'd like to receive information from {this.props.platformName} related to events by email, or other forms of communication.</p>

            <p>You may unsubscribe at any time by following the instructions in the communications received. Your information will be handled in accordance with the Privacy Policy.</p>

            <label for="terms_agree" className="em-checkbox">
                <input id="terms_agree" type="checkbox" name="terms_agree" onChange={this.props.onAgreementChange} checked={this.props.agreementState} />
                <span>I acknowledge that I have read and agree to the <a href={this.props.termsUrl} target="_blank" className="link">{this.props.platformName} Terms</a>.</span>
            </label>
            {this.props.errors == "required" && (
              <span className="error_marker" role="alert">
                You must agree to the terms
              </span>
            )}
          
        </div>`

      # Render the inline terms acceptance message
      renderInlineTerms: =>
        `<div>
            <div className="em-basic-input">
              <textarea value={this.props.text} rows="10" readOnly></textarea>
            </div>
            
          
            <label for="terms_agree" className="em-checkbox">
                <input id="terms_agree" type="checkbox" name="terms_agree" onChange={this.props.onAgreementChange} checked={this.props.agreementState} />
                <span>I acknowledge that I have read and agree to the terms above.</span>
            </label>
            {this.props.errors == "required" && (
              <span className="error_marker" role="alert">
                You must agree to the terms
              </span>
            )}
          
        </div>`



      render: =>
        switch @props.level
          when 'light'
            @renderImplicit()
          when 'strong'
            @renderExplicit()
          when 'hardcore'
            @renderInlineTerms()
          else
            null
